<template>
  <div class="mobileScreen-main-container">
    <div
      class="mobileScreen-internet-container relativeclass d-flex justify-content-center h-100 flex-column"
    >
      <div
        style="height: 90%;"
        class="d-flex align-items-center justify-content-center flex-column"
      >
        <div class="mobileScreen-internet-head mb-4">
          Check Your Internet Speed.
        </div>
        <div
          class="mobileScreen-internet-head stablemsg mb-4"
          v-if="isNetSpeed && checkupfinish"
        >
          Your internet speed is stable. Click "Next" to proceed with the test
        </div>

        <div
          class="mt-3 mobileScreen-internet-innerContainer d-flex flex-column align-items-center"
        >
          <div class="d-flex justify-content-between w-100 ">
            <div class="d-flex">
              <div class="mobileScreen-internet-upload mt-1"></div>
              <div class="ml-2">
                <div class="mobileScreen-upload-txt">
                  Upload Mbps
                </div>
                <div>
                  {{ downloadSpeed }}
                </div>
              </div>
            </div>

            <div class="d-flex ml-4">
              <div class="mobileScreen-internet-download mt-1"></div>
              <div class="ml-2">
                <div class="mobileScreen-upload-txt">
                  Download Mbps
                </div>
                <div>
                  {{ uploadSpeed }}
                </div>
              </div>
            </div>
          </div>
          <div class="mi-guage position-relative mt-4 mb-4">
            <div class="mi-guage__body">
              <div
                class="mi-guage__fill"
                :style="{
                  transform:
                    'rotate(' +
                    mapToRange(downloadSpeed, 1, 100, 1, 180) +
                    'deg)',
                }"
              ></div>
              <div class="mi-guage__cover">
                <img
                  :style="`transform: rotate(${angleValue}deg)`"
                  class="mi-speedometer-origin"
                  id="speed-img"
                  src="../../assets/newimgs/speedometerArrow copy.svg"
                  alt=""
                />
              </div>
            </div>
            <div
              class="position-absolute mi-speedometer-txt"
              style="left: -26px;bottom: -5px;"
            >
              0
            </div>
            <div
              class="position-absolute mi-speedometer-txt"
              style="left: -18px; bottom: 43px;"
            >
              10
            </div>
            <div
              class="position-absolute mi-speedometer-txt"
              style="top: -20px; left: 26px;"
            >
              30
            </div>
            <div
              class="position-absolute mi-speedometer-txt"
              style="top: -35px; right: 66px;"
            >
              50
            </div>
            <div
              class="position-absolute mi-speedometer-txt"
              style="top: -20px; right: 18px;"
            >
              70
            </div>
            <div
              class="position-absolute mi-speedometer-txt"
              style="right: -20px; bottom: 39px;"
            >
              90
            </div>
            <div
              class="position-absolute mi-speedometer-txt"
              style="right: -52px; bottom: -5px;"
            >
              100+
            </div>
          </div>
        </div>
        <div class="mt-4 bluebtns">
          <div>
            <b-button
              v-if="isNetSpeed && checkupfinish"
              @click="next()"
              class="mobileScreen-leaveBtn"
              style="width: 300px;"
              >Next</b-button
            >
            <b-button
              v-else
              :disabled="!checkupfinish"
              @click="resetAllValues()"
              class="mobileScreen-leaveBtn"
              style="width: 300px;"
              >Retry</b-button
            >
          </div>
        </div>
      </div>
      <div
        class="copyrights-font d-flex align-items-end justify-content-center mb-3"
        style="height: 10%;"
      >
        Copyrights &copy; 2024. All rights reserved
      </div>
    </div>
  </div>
</template>

<script>
import internet from "../../mixins/internet"
export default {
  data() {
    return {
      property: "value",
    };
  },
  mixins: [internet],
  methods: {
    newPage() {
      this.$router.push({ name: "MNewInternetCheck" });
    },
    mapToRange(value, inputMin, inputMax, outputMin, outputMax) {
      value = Math.min(Math.max(value, inputMin), inputMax);
      let a = (outputMax - outputMin) / (inputMax - inputMin);
      let b = outputMin - a * inputMin;
      return a * value + b;
    },
  }
};
</script>

<style scoped>
.stablemsg {
  text-align: center;
  padding: 0 10px;
}

/* Style the speedbox base */
.speedbox__base {
  width: 119%;
  height: 145px;
  background: linear-gradient(to bottom, #717d91, #1c2b43);
  position: relative;
  top: px;
  z-index: 1000;
}

.speedbox {
  margin: 2em auto;
  height: 275px;
  width: 275px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/* Style the speedbox groove */
.speedbox__groove {
  height: 138px;
  width: 275px;
  background: transparent;
  border-top-left-radius: 150px;
  border-top-right-radius: 150px;
  border: 20px solid #eee;
  border-bottom: 0;
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
}

.speedbox__score {
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(-45deg);
  height: 275px;
  width: 275px;
  background: transparent;
  border-radius: 50%;
  border: 20px solid #5c6f7b;
  border-color: transparent transparent #009109 #009109;
  box-sizing: border-box;
  cursor: pointer;
  z-index: 1;
  transition: transform 0.3s ease;
}

.mi-guage {
  border: 8.107px solid #f5eeee;
  border-bottom: none;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  width: 100%;
  max-width: 170px;
}

.mi-guage__body {
  width: 100%;
  height: 0;
  padding-bottom: 50%;
  background: linear-gradient(180deg, #f4f4f4 0%, rgba(244, 244, 244, 0) 132%);
  position: relative;
  border-top-left-radius: 100% 200%;
  border-top-right-radius: 100% 200%;
  overflow: hidden;
}

.mi-guage__fill {
  background: #032e72;
  position: absolute;
  top: 100%;
  left: 0;
  width: inherit;
  height: 100%;
  transform-origin: center top;

  transition: transform 0.2s ease-out;
}

.mi-guage__cover {
  border: 8.107px solid #f5eeee;
  background: #334461;
  width: 94%;
  height: 187%;
  border-radius: 50%;
  position: absolute;
  top: 7%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 28%;
}

.mi-speedometer-origin {
  transform-origin: 50% 70%;
  transition: 0.5s all linear;
}

.mi-speedometer-txt {
  color: #fff;
  font-weight: 400;
}
</style>
